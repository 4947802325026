import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { HROUTES } from './navigation-routes.config';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { RespuestaDto } from 'models/response/respuesta-dto';
import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';
import { InfoMessagesService } from '../messages/info-messages.service';
import { CrudServiceService } from '../backend/cruds/crud-service.service';
import { TokenStorageService } from '../storage-services/token-storage.service';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss']
})
export class HorizontalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  public menuItems: any[];
  public config: any = {};
  level: number = 0;
  transparentBGClass = "";
  menuPosition = 'Side';

  layoutSub: Subscription;

  constructor(
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private messageService: InfoMessagesService,
    private crudService: CrudServiceService,
    private tokenStorage: TokenStorageService
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit() {
    this.menuItems = HROUTES;
    this.filterMenu();
  }

  ngAfterViewInit() {

    this.layoutSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != "") {
      this.menuPosition = this.config.layout.menuPosition;
    }
    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    }
    else {
      this.transparentBGClass = "";
    }

  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  filterMenu() {

    this.menuItems = [];
    HROUTES.splice(0, HROUTES.length);

    let url = 'api/modulo/obtener-modulos-menus?idPerfil=' + this.tokenStorage.getPerfilId();
    this.crudService.getModel(url).toPromise().then((respuesta: RespuestaDto) => {
      if (respuesta.codigo === 200) {
        const variable = respuesta.objeto;

        for (const i in variable) {
          const subMenuArray = []
          for (const subMenu of variable[i].listMenu) {
            subMenuArray.push(
            {
              path: subMenu.ruta, title: subMenu.titulo, icon: subMenu.icono, class:
                '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            })
          }

          const modulo: RouteInfo = {
            path: '', title: variable[i].module.nombre, icon: variable[i].module.icono,
            class: 'has-sub open', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
            isExternalLink: false, submenu: subMenuArray
          }

          HROUTES.push(modulo);
          this.menuItems = HROUTES
        }
      }
      else {
        this.messageService.getInfoMessagePersonalized('error', respuesta.descripcion, 'No se cargaron lo módulos')
      }
    })
    .catch(error => {
      this.messageService.getInfoMessagePersonalized('error', 'No se pudieron cargar los módulos', 'Atención!');
    })
  }

}
