import { TokenStorageService } from './../../shared/storage-services/token-storage.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RespuestaDto } from 'models/response/respuesta-dto';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  show: boolean;
  show1: boolean;
  show2: boolean;
  form: any = {};
  passwordValidationError = '';
  isErrorPassword = false;

  @ViewChild('changePasswordForm') changePasswordForm: NgForm;

  constructor(
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorageService: TokenStorageService,
    private modalService: NgbModal
  ) {
    this.show = false;
    this.show1 = false;
    this.show2 = false;
   }

  ngOnInit() {
    this.form.newPasswordConfirm = '';
    this.form.newPassword = '';
    this.form.oldPassword = '';
  }

  changePassword() {

    if (this.form.newPassword === this.form.newPasswordConfirm) {
      const passwordInfoUpdate = {
        'idInfoUsuario': this.tokenStorageService.getIdInfoUsuario(),
        'contrasenaAntigua': this.form.oldPassword,
        'contrasenaNueva': this.form.newPassword
      }

      this.crudServices.createModel('api/contrasena/cambiar-contrasena-us', passwordInfoUpdate).toPromise().then((
        respuesta: RespuestaDto) => {
          if (respuesta.codigo === 200) {
            this.messageService.getInfoMessageUpdate().then(() => {
              this.modalService.dismissAll();
            })
          }
          else if (respuesta.codigo === 400) {
            this.messageService.getInfoMessagePersonalized('warning', respuesta.descripcion, 'Atención!')
          }
      })
      .catch(error => {
        this.messageService.getInfoMessagePersonalized('error', 'Ha ocurrido algo, intentalo de nuevo', 'Atención!');
      });
    }
    else {
      this.passwordValidationError = '¡Las contraseñas no coinciden!';
      this.isErrorPassword = true;
      setTimeout(() => { this.isErrorPassword = false; }, 3000);
    }
  }
  password(){
    this.show = !this.show
  }
  password1(){
    this.show1 = !this.show1
  }
  password2(){
    this.show2 = !this.show2
  }

  closeWindow() {
    this.modalService.dismissAll();
  }
}
