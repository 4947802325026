import { Injectable } from '@angular/core';

const TOKEN_KEY = 'authToken';
const TOKEN_KEY_THURNI = 'token-thurni';
const USERNAME_KEY = 'authUsername';
const COMPLETE_NAME_KEY = 'authCompletename';
const USUARIO_ID = 'usuarioId';
const USUARIO_INFO_ID = 'usuarioInfoId';
const AUTHORITIES_KEY = 'authKey';
const CREATED_DATE = 'createdDate';
const PROFILE_ID = 'profileId';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
z
  constructor() { }

  signOut() {
    window.sessionStorage.clear()
  }

  public saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public saveTokenThurni(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY_THURNI);
    window.sessionStorage.setItem(TOKEN_KEY_THURNI, token);
  }

  public getTokenThurni(): string {
    return sessionStorage.getItem(TOKEN_KEY_THURNI);
  }

  public savePerfilId(perfil: string) {
    window.sessionStorage.removeItem(PROFILE_ID);
    window.sessionStorage.setItem(PROFILE_ID, perfil);
  }

  public getPerfilId(): string {
    return sessionStorage.getItem(PROFILE_ID);
  }

  public saveCompleteName(completeName: string) {
    window.sessionStorage.removeItem(COMPLETE_NAME_KEY);
    window.sessionStorage.setItem(COMPLETE_NAME_KEY, completeName);
  }

  public getCompleteName(): string {
    return sessionStorage.getItem(COMPLETE_NAME_KEY);
  }

  public saveUsername(username: string) {
    window.sessionStorage.removeItem(USERNAME_KEY);
    window.sessionStorage.setItem(USERNAME_KEY, username);
  }

  public saveIdUsuario(id: string) {
    window.sessionStorage.removeItem(USUARIO_ID);
    window.sessionStorage.setItem(USUARIO_ID, id);
  }

  public getIdUsuario(): string {
    return sessionStorage.getItem(USUARIO_ID);
  }

  public saveIdInfoUsuario(id: string) {
    window.sessionStorage.removeItem(USUARIO_INFO_ID);
    window.sessionStorage.setItem(USUARIO_INFO_ID, id);
  }

  public getIdInfoUsuario(): string {
    return sessionStorage.getItem(USUARIO_INFO_ID);
  }

  public getUsername(): string {
    return sessionStorage.getItem(USERNAME_KEY);
  }

  public saveAuthorities(authorities: string) {
    window.sessionStorage.removeItem(AUTHORITIES_KEY);
    window.sessionStorage.setItem(AUTHORITIES_KEY, authorities);
  }

  public saveCreatedDate(createdDate: string) {
    window.sessionStorage.removeItem(CREATED_DATE);
    window.sessionStorage.setItem(CREATED_DATE, createdDate);
  }

  public getCreatedDate(): string {
    return sessionStorage.getItem(CREATED_DATE);
  }

  public getAuthorities(): string {
    try {
      if (sessionStorage.getItem(TOKEN_KEY)) {
        return sessionStorage.getItem(AUTHORITIES_KEY);
      }
    } catch (error) {
    }
  }
}
