import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenStorageService } from '../storage-services/token-storage.service';

@Injectable()
export class AuthService {

  errorMessage: string;

  headers = {
    'Authorization': `Basic c3ByaW5nLXNlY3VyaXR5LW9hdXRoMi1yZWFkLXdyaXRlLWNsaWVudDpzcHJpbmctc2VjdXJpdHktb2F1dGgyLXJlYWQtd3JpdGUtY2xpZW50LXBhc3N3b3JkMTIzNA==`
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  token: TokenStorageService;
  private serverURL = environment.serverUrl;
  private serverURLThurni = environment.serverUrlThurni;

  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorageService
  ) {
    this.isAuthenticated()
  }

  async buildHeaderAuthenticated() {
    this.headers = {
      'Authorization': this.tokenStorage.getToken() + ''
    };
  }

  logout() {
    this.token = null;
  }

  getToken() {
    return this.token;
  }

  signinUser(path, credentials): Observable<any> {
    
    return this.http.post<any>(`${this.serverURL}${path}`, credentials, this.httpOptions);
  }

  signinUserThurni(path, credentials): Observable<any> {
    this.headers = {
      'Authorization': `Basic c3ByaW5nLXNlY3VyaXR5LW9hdXRoMi1yZWFkLXdyaXRlLWNsaWVudDpzcHJpbmctc2VjdXJpdHktb2F1dGgyLXJlYWQtd3JpdGUtY2xpZW50LXBhc3N3b3JkMTIzNA==`
    }
    return this.http.post<any>(`${this.serverURLThurni}${path}`, credentials, { headers: this.headers });
  }

  isAuthenticated() {
    if (this.tokenStorage.getToken() != null) {
      this.buildHeaderAuthenticated();
      return true;
    }
    else {
      return false;
    }
  }
}
