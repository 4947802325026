import { Injectable } from '@angular/core';
import { MenuModel } from 'models/entities/menu-model';

@Injectable()
export class MenusUsuarioService {

  listaMenus: Array<MenuModel> = new Array();

  constructor() {

    this.listaMenus = JSON.parse(window.sessionStorage.getItem("menusUsuario") || "[]");
  }
}
