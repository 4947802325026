import { Injectable } from '@angular/core';

@Injectable()
export class SeleccionMenuService {

  menus = {};

  constructor() {
  }

  setMenu(menu) {
    this.menus[menu.path] = menu;
  }

  getMenu(path) {
    return this.menus[path];
  }
}
