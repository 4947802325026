import { Routes, RouterModule } from "@angular/router";

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "calendar",
    loadChildren: () =>
      import("../../calendar/calendar.module").then((m) => m.CalendarsModule),
  },
  {
    path: "charts",
    loadChildren: () =>
      import("../../charts/charts.module").then((m) => m.ChartsNg2Module),
  },
  {
    path: "forms",
    loadChildren: () =>
      import("../../forms/forms.module").then((m) => m.FormModule),
  },
  {
    path: "maps",
    loadChildren: () =>
      import("../../maps/maps.module").then((m) => m.MapsModule),
  },
  {
    path: "tables",
    loadChildren: () =>
      import("../../tables/tables.module").then((m) => m.TablesModule),
  },
  {
    path: "datatables",
    loadChildren: () =>
      import("../../data-tables/data-tables.module").then(
        (m) => m.DataTablesModule
      ),
  },
  {
    path: "uikit",
    loadChildren: () =>
      import("../../ui-kit/ui-kit.module").then((m) => m.UIKitModule),
  },
  {
    path: "components",
    loadChildren: () =>
      import("../../components/ui-components.module").then(
        (m) => m.UIComponentsModule
      ),
  },
  {
    path: "pages",
    loadChildren: () =>
      import("../../pages/full-pages/full-pages.module").then(
        (m) => m.FullPagesModule
      ),
  },
  {
    path: "cards",
    loadChildren: () =>
      import("../../cards/cards.module").then((m) => m.CardsModule),
  },
  {
    path: "chat",
    loadChildren: () =>
      import("../../chat/chat.module").then((m) => m.ChatModule),
  },
  {
    path: "chat-ngrx",
    loadChildren: () =>
      import("../../chat-ngrx/chat-ngrx.module").then((m) => m.ChatNGRXModule),
  },
  {
    path: "inbox",
    loadChildren: () =>
      import("../../inbox/inbox.module").then((m) => m.InboxModule),
  },
  {
    path: "taskboard",
    loadChildren: () =>
      import("../../taskboard/taskboard.module").then((m) => m.TaskboardModule),
  },
  {
    path: "taskboard-ngrx",
    loadChildren: () =>
      import("../../taskboard-ngrx/taskboard-ngrx.module").then(
        (m) => m.TaskboardNGRXModule
      ),
  },
  {
    path: "modulos",
    loadChildren: () =>
      import("../../modules/modules.module").then((m) => m.ModulesModule),
  },
  {
    path: "usuarios",
    loadChildren: () =>
      import("../../usercomponents/crud-user.module").then(
        (m) => m.CrudUserModule
      ),
  },
  {
    path: "menus",
    loadChildren: () =>
      import("../../menucomponent/menucomponent.module").then(
        (m) => m.MenucomponentModule
      ),
  },
  {
    path: "perfiles",
    loadChildren: () =>
      import("../../profile/profile.module").then((m) => m.ProfileModule),
  },
  {
    path: "tipos-clase",
    loadChildren: () =>
      import("../../tipos-clase/tipo-clase.module").then(
        (m) => m.TipoClaseModule
      ),
  },
  {
    path: "lineas",
    loadChildren: () =>
      import("../../lineas/lineas.module").then((m) => m.LineasModule),
  },
  {
    path: "calificaciones",
    loadChildren: () =>
      import("../../calificaciones/calificaciones.module").then(
        (m) => m.CalificacionesModule
      ),
  },
  {
    path: "preguntas",
    loadChildren: () =>
      import("../../preguntas/preguntas.module").then((m) => m.PreguntasModule),
  },
  {
    path: "criterios",
    loadChildren: () =>
      import("../../criterio/criterio.module").then((m) => m.CriterioModule),
  },
  {
    path: "parrafo",
    loadChildren: () =>
      import("../../parrafo/parrafo.module").then((m) => m.ParrafoModule),
  },

  {
    path: "clases",
    loadChildren: () =>
      import("../../ordenar/ordenar.module").then((m) => m.OrdenarModule),
  },
  {
    path: "clases",
    loadChildren: () =>
      import("../../articulo/articulo.module").then((m) => m.ArticuloModule),
  },
  {
    path: "completa-parrafo",
    loadChildren: () =>
      import("../../completa-parrafo/completa-parrafo.module").then(
        (m) => m.CompletaParrafoModule
      ),
  },
  {
    path: "verdadero-falso",
    loadChildren: () =>
      import("../../verdadero-falso/verdadero-falso.module").then(
        (m) => m.VerdaderoFalsoModule
      ),
  },
  {
    path: "academia",
    loadChildren: () =>
      import("../../academias/academias.module").then((m) => m.AcademiasModule),
  },
  {
    path: "criterios",
    loadChildren: () =>
      import("../../criterio/criterio.module").then((m) => m.CriterioModule),
  },
  {
    path: "secciones",
    loadChildren: () =>
      import("../../secciones/secciones.module").then((m) => m.SeccionesModule),
  },
  {
    path: "parrafo",
    loadChildren: () =>
      import("../../parrafo/parrafo.module").then((m) => m.ParrafoModule),
    data: {
      breadcrumb: { disable: true, alias: "preguntas", label: "preguntas" },
    },
  },
  {
    path: "enlaces",
    loadChildren: () =>
      import("../../enlace/enlace.module").then((m) => m.EnlaceModule),
  },

  {
    path: "cursos",
    loadChildren: () =>
      import("../../cursos/cursos.module").then((m) => m.CursosModule),
    data: { breadcrumb: { disable: true, alias: "Cursos" } },
  },
  {
    path: "cursos",
    loadChildren: () =>
      import("../../cursos-estudiante/cursos-estudiante.module").then((m) => m.CursosEstudianteModule),
    data: { breadcrumb: { disable: true, alias: "Cursos" } },
  },
  {
    path: "estudiantes",
    loadChildren: () =>
      import("../../estudiantes/estudiantes.module").then(
        (m) => m.EstudiantesModule
      ),
    data: { breadcrumb: { disable: true, alias: "Estudiantes" } },
  },
  {
    path: "clases",
    loadChildren: () =>
      import("../../clases/clases.module").then((m) => m.ClasesModule),
  },

  {
    path: "enlazar-preguntas",
    loadChildren: () =>
      import("../../enlazar-preguntas/enlazar-preguntas.module").then(
        (m) => m.EnlazarPreguntasModule
      ),
  },
  {
    path: "quices",
    loadChildren: () =>
      import("../../quiz/quiz.module").then((m) => m.QuizModule),
  },
  {
    path: "documentos",
    loadChildren: () =>
      import("../../documento/documento.module").then((m) => m.DocumentoModule),
  },
  {
    path: "responder-seleccion-multiple",
    loadChildren: () =>
      import(
        "../../respuesta-seleccion-multiple/respuesta-seleccion-multiple.module"
      ).then((m) => m.RespuestaSeleccionMultipleModule),
  },
  {
    path: "catalogo-cursos",
    loadChildren: () =>
      import("../../catalogo-cursos/catalogo-cursos.module").then((m) => m.CatalogoCursosModule),
  },
  {
    path: "responder",
    loadChildren: () =>
      import("../../responder-archivo/responder-archivo.module").then((m) => m.ResponderModule),
  },
  {
    path: "rendimiento",
    loadChildren: () =>
      import("../../rendimiento/rendimiento.module").then((m) => m.RendimientoModule),
  },
];
