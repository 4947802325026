import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class InfoMessagesService {
  public message: string;
  public type: string;

  constructor() { }

  getInfoMessageError() {
    return Swal.fire({
      title: '<strong> Error en el servidor! </strong>',
      icon: 'error',
      html: 'Ocurrio un problema durante la operación',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Cerrar!'
    })
  }

  getInfoMessageCreate(): Promise<any> {
    return Swal.fire({
      title: '<strong> Correcto </strong>',
      icon: 'success',
      html: 'Registro agregado correctamente',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Cerrar!'
    })
  }

  getInfoMessageDelete(): Promise<any> {
    return Swal.fire({
      title: '<strong> Correcto </strong>',
      icon: 'success',
      html: 'Registro eliminado',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Cerrar!'
    })
  }

  getInfoMessageUpdate(): Promise<any> {
    return Swal.fire({
      title: '<strong> Correcto </strong>',
      icon: 'success',
      html: 'Registro actualizado',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Cerrar!'
    })
  }

  getInfoMessageBadInternet() {
    return Swal.fire({
      title: '<strong> Problemas de conexión! </strong>',
      icon: 'warning',
      html: 'Ha ocurrido un problema, por favor revisa tu conexión a internet',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Cerrar!'
    })
  }

  getInfoMessagePersonalized(typeMessage, message, titleMessage) {
    return Swal.fire({
      title: '<strong> ' + titleMessage + '</strong>',
      icon: typeMessage,
      html: message,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Cerrar!'
    })
  }
}
