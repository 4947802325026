<div class='mainContainer'>

  <div class="modal-header">
    <h4>Actualización de Contraseña</h4>
    <button type="button" class="close" (click)="closeWindow()" style="background-color: red" aria-label="Close">
      <span aria-hidden="true" style="color:white">&times;</span>
    </button>
  </div>

  <section id="update">
    <div class="card-block mx-auto">

      <form (ngSubmit)="f.form.valid" #f="ngForm">

        <div class="form-group" style="margin-top: 2%;">
          <label>Ingrese la contraseña actual</label>
          <input [type]="show ? 'text' : 'password'" type="password" class="form-control" name="oldPassword" id="oldPassword" [(ngModel)]="form.oldPassword"
            #oldPassword="ngModel" minlength="8" maxlength="12" required>
            <button type="button" class="btn px-2" (click)="password()" style="background-color: rgba(255, 255, 255, 0.7)" >
                 <i class="fa fa-eye" aria-hidden="true"></i>
           </button>
        </div>

        <div class="form-group">
          <label>Ingrese la nueva contraseña</label>
          <input [type]="show1 ? 'text' : 'password'" type="password" class="form-control" name="newPassword" id="newPassword" [(ngModel)]="form.newPassword"
            #newPassword="ngModel" minlength="8" maxlength="12" required>
            <button type="button" class="btn px-2" (click)="password1()" style="background-color: rgba(255, 255, 255, 0.7)" >
              <i class="fa fa-eye" aria-hidden="true"></i>
        </button>
        </div>

        <div class="form-group">
          <label>Ingrese de nuevo la contraseña nueva</label>
          <input [type]="show2 ? 'text' : 'password'" type="password" class="form-control" name="newPasswordConfirm" id="newPasswordConfirm"
            [(ngModel)]="form.newPasswordConfirm" #newPasswordConfirm="ngModel"
            minlength="8" maxlength="12" required>
            <button type="button" class="btn px-2" (click)="password2()" style="background-color: rgba(255, 255, 255, 0.7)" >
              <i class="fa fa-eye" aria-hidden="true"></i>
        </button>
        </div>

        <div class="alert alert-danger" *ngIf="isErrorPassword">
          {{passwordValidationError}}
        </div>

        <div class="form-group text-center">
          <button (click)="changePassword()" [disabled]="!f.valid || isErrorPassword" type="button"
          class="btn btn-primary btn-raised">Actualizar Contraseña</button>
        </div>
      </form>
    </div>
  </section>
</div>
