import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CrudServiceService {

  private serverURL = environment.serverUrl;
  private serverURLThurni = environment.serverUrlThurni;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token.getToken()
    })
  };

  httpOptionsPdf = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token.getToken()
    }),
    responseType: 'blob' as 'json'
  }

  httpOptionsMultipart = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.token.getToken()
      // 'Content-Type': `multipart/form-data; boundary=${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`
    })
  };


  httpOptionsThurni = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token.getTokenThurni()
    })
  };

  httpOptionsFile = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.token.getToken(),
    }),
  };

  constructor(
    private http: HttpClient,
    private token: TokenStorageService
  ) { }


  buildHeaders() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token.getToken()
      })
    };
  }

  buildHeadersThurni() {
    this.httpOptionsThurni = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token.getTokenThurni()
      })
    };
  }

  buildHeadersFile() {
    this.httpOptionsFile = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer' + this.token.getToken(),
      }),
    };
  }

  public createModelWA(path, model): Observable<any> {
    return this.http.post<any>(`${this.serverURL}${path}`, model, {});
  }

  public getModelWA(path): Observable<any> {
    return this.http.get<any>(`${this.serverURL}${path}`, {});
  }

  public createModel(path, model): Observable<any> {
    return this.http.post<any>(`${this.serverURL}${path}`, model, this.httpOptions);
  }

  public createModelMultipart(path, model): Observable<any> {
    return this.http.post<any>(`${this.serverURL}${path}`, model, this.httpOptionsMultipart);
  }

  public createModelParams(path, model, params): Observable<any> {
    return this.http.post<any>(`${this.serverURL}${path}`, model, { headers: this.httpOptions.headers, params: params });
  }

  public putModelParams(path, model, params): Observable<any> {
    return this.http.put<any>(`${this.serverURL}${path}`, model, { headers: this.httpOptions.headers, params: params });
  }

  public postModelFilePart(path, model): Observable<any> {    
    return this.http.post<any>(`${this.serverURL}${path}`,model, this.httpOptionsMultipart);
  }

  public putModel(path, model): Observable<any> {
    return this.http.put<any>(`${this.serverURL}${path}`, model, { headers: this.httpOptions.headers });
  }

  public putModelMultipart(path, model): Observable<any> {
    return this.http.put<any>(`${this.serverURL}${path}`, model, this.httpOptionsMultipart);
  }

  public deleteModelParams(path, model, params): Observable<any> {
    return this.http.put<any>(`${this.serverURL}${path}`, model, { headers: this.httpOptions.headers, params: params });
  }

  public getModel(path): Observable<any> {
    this.buildHeaders()
    return this.http.get<any>(`${this.serverURL}${path}`, this.httpOptions);
  }

  public getModelModel(path, model): Observable<any> {
    return this.http.post<any>(`${this.serverURL}${path}`, model, { headers: this.httpOptions.headers });
  }

  public deleteModel(path): Observable<any> {
    return this.http.delete<any>(`${this.serverURL}${path}`, this.httpOptions);
  }

  public deleteModelWA(path): Observable<any> {
    return this.http.delete<any>(`${this.serverURL}${path}`, {});
  }

  // INTERACCION CERTIFICADO PDF
  public descargarCertificadoPdf(path, model): Observable<Blob> {
    return this.http.post<Blob>(`${this.serverURL}${path}`, model, this.httpOptionsPdf);
  }

  // INTERACCION SERVICIOS ARCHIVOS S3
  public createFile(path, model): Observable<any> {
    this.buildHeadersFile();
    return this.http.post<any>(`${this.serverURL}${path}`, model, this.httpOptionsFile
    );
  }


  /////////////////////////////////// SERVICIOS PARA THURNI ////////////////////////////////////////

  public getModelThurni(path): Observable<any> {
    this.buildHeadersThurni();
    return this.http.get<any>(`${this.serverURLThurni}${path}`, this.httpOptionsThurni);
  }

}
