export const environment = {
  production: true,

  serverUrlThurni: "http://localhost:8081/", // Ambiente de thurni

  // serverUrl: "http://localhost:8080/", // Ambiente Local 8080
  // serverUrl: "http://54.237.110.96:8080/", // Ambiente de pruebas
  serverUrl: "https://app.tinalms.com/",  //Ambiente de producción
  // serverUrl: "http://52.45.21.11:8080/", // Ambiente de producción sin DNS


  // id's mongodb
  idCursosEstudiantes: "63ab692d2b965974ec624caa",
  idGestionCurso: "63ab67f62b965974ec624ca6",
  idClaseOrdenar: "6356f9bee63cc1442292073f",
  idTipoQuiz: "6356f98be63cc1442292073e",
  idEnlaceExterno: "636141ae1262c60b81f38ed5",
  idClaseDocumento: "636143841262c60b81f38ed6",
  idClaseArticulo: "6376c24943e1b315b8421dfb",
  idFalsoVerdadero: "638664f8703ee31a2f3a2360",
  idSeleccionMultiple: "634ee177acf9db3fb8104d5d",
  idCompletarParrafo: "634ee166acf9db3fb8104d5c",
  idEmparejamiento: "634ee153acf9db3fb8104d5b",
};
