import { Component, OnInit, ViewChild, OnDestroy, ElementRef, AfterViewInit, ChangeDetectorRef,
  HostListener } from "@angular/core";
import { ROUTES } from './vertical-menu-routes.config';
import { HROUTES } from '../horizontal-menu/navigation-routes.config';
import { environment } from '../../../environments/environment.prod'

import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import { RespuestaDto } from "models/response/respuesta-dto";
import { RouteInfo } from "./vertical-menu.metadata";
import { CrudServiceService } from "../backend/cruds/crud-service.service";
import { TokenStorageService } from "../storage-services/token-storage.service";
import { InfoMessagesService } from "../messages/info-messages.service";
import { SeleccionMenuService } from "../auth/seleccion-menu";

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level: number = 0;
  logoUrl = 'assets/img/gallery/logoLmsHorizontal.svg';
  logoUrl2 = 'assets/img/gallery/perrita.svg';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;

  orientation = 'V';

  constructor(
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private crudServices: CrudServiceService,
    private tokenStorage: TokenStorageService,
    private messageService: InfoMessagesService,
    private seleccionMenuService: SeleccionMenuService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }


  ngOnInit() {
    this.menuItems = ROUTES;

    if (this.isLogin()) {
      this.filterMenu(this.orientation);
    }
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }

      this.loadLayout();
      this.cdr.markForCheck();
    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      });

  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
      if (this.resizeTimeout) {
          clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout((() => {
        this.innerWidth = event.target.innerWidth;
          this.loadLayout();
      }).bind(this), 500);
  }

  loadLayout() {

    if (this.config.layout.menuPosition === "Top") { // Horizontal Menu
      if (this.innerWidth < 1200) { // Screen size < 1200
        this.menuItems = HROUTES;
        this.orientation = 'H';
      }
    }
    else if (this.config.layout.menuPosition === "Side") { // Vertical Menu{
      this.menuItems = ROUTES;
      this.orientation = 'V';
    }

    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/gallery/logoLmsHorizontal.svg';
    }
    else {
      this.logoUrl = 'assets/img/gallery/logoLmsHorizontal.svg';
    }

    if(this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    }
    else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    }
    else {
      this.perfectScrollbarEnable = true;
    }
  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  isLogin() {
    if (this.tokenStorage.getToken() === null) {
      return false
    }
    else {
      return true
    }
  }

  filterMenu(orientation) {

    this.menuItems = [];
    HROUTES.splice(0, HROUTES.length);
    ROUTES.splice(0, ROUTES.length);

    let url = 'api/modulo/obtener-modulos-menus?idPerfil=' + this.tokenStorage.getPerfilId();
    this.crudServices.getModel(url).toPromise().then((respuesta: RespuestaDto) => {

      if(respuesta.codigo === 200) {
        const modulosBD = respuesta.objeto;

        for (const i in modulosBD) {
          const subMenuArray = []
          for (const subMenu of modulosBD[i].listaMenus) {

            
            if(subMenu.id === environment.idGestionCurso || subMenu.id === environment.idCursosEstudiantes) continue;

            let objetoMenu = {
              path: subMenu.ruta, title: subMenu.nombre, icon: subMenu.icono, class:
              '', badge: '', badgeClass: '', isExternalLink: false,
              accionVer: subMenu.accionVer, accionCrear: subMenu.accionCrear, accionEditar: subMenu.accionEditar,
              accionBorrar: subMenu.accionEliminar, submenu: []
            }

            this.seleccionMenuService.setMenu(objetoMenu);
            subMenuArray.push(objetoMenu);
          }

          const modulo: RouteInfo = {
            path: '', title: modulosBD[i].nombre, icon: modulosBD[i].icono,
            class: 'has-sub open', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
            isExternalLink: false, submenu: subMenuArray
          }

          if(orientation === 'H') { // HORIZONTAL
            HROUTES.push(modulo);
            this.menuItems = HROUTES
          }
          else { // VERTICAL
            ROUTES.push(modulo);
            this.menuItems = ROUTES
          }
        }

        document.getElementById('menu_id').click();
      }
      else if(respuesta.codigo === 400) {
        this.messageService.getInfoMessagePersonalized('warning', respuesta.descripcion, 'Atención!');
      }
    })
    .catch(error => {
      this.messageService.getInfoMessagePersonalized('error', 'No se pudieron cargar los módulos', 'Atención!');
    })
  }

}
